.section {
  :global(.background) {
    height: calc(100% - 200px) !important;
  }

  .content {
    text-align: center;
    max-width: 864px;
    margin-right: auto;
    margin-left: auto;

    b,
    strong {
      color: var(--color-black);
    }
  }

  .dude {
    min-width: 99.8vw;
    width: 2560px;
    height: 741px;
    pointer-events: none;

    &__inner {
      min-width: 99.8vw;
      width: 2560px;
      height: 741px;
      margin: 0px auto;
      position: absolute;
      right: -100%;
      left: -100%;
      z-index: 55;

      > * {
        display: block;
        width: 100%;
        max-height: 741px;
      }
    }
  }
}
