.main-button {
  font-family: 'Axiforma', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.01em;
  text-align: center;
  background-color: initial;
  border: initial;
  outline: initial;
  padding: initial;
  position: relative;
  user-select: none;
  cursor: pointer;

  transition: var(--transition-fast);

  &__background {
    border-radius: 8px;
    box-shadow: var(--shadow-xs);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    transition: var(--transition-fast);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;

      transition: var(--transition-fast);

      &:nth-child(1) {
        margin-left: -8px;
      }

      svg {
        transition: var(--transition-fast);
      }
    }

    &__text {
      transition: var(--transition-fast);
    }

    &__text + &__icon {
      margin-left: 8px;
    }

    &__icon + &__text {
      // margin-right: -8px;
      margin-left: 8px;
    }
  }

  &--color-main {
    color: var(--color-white);

    .main-button__background {
      background-color: var(--color-main);
    }

    &:hover {
      .main-button__background {
        background-color: var(--Fill-Brand-Orange-Hover);
      }
    }
  }

  &--color-white {
    color: var(--color-main);

    .main-button__background {
      background-color: var(--color-white);
      border: 1px solid var(--color-gray-300);
    }

    &:hover,
    :global(.button-container-for-hover:hover) & {
      .main-button__background {
        background-color: var(--color-main);
      }

      .main-button__inner {
        &__icon {
          svg {
            fill: var(--color-white) !important;
          }
        }

        &__text {
          color: var(--color-white);
        }
      }
    }
  }

  &--color-blank {
    color: var(--color-white);

    .main-button__background {
      border: 1px solid rgba(255, 255, 255, 0.25);
    }

    &:hover {
      .main-button__background {
        background-color: var(--color-white);
      }

      .main-button__inner {
        &__icon {
          svg {
            fill: var(--color-space-blue-1) !important;
          }
        }

        &__text {
          color: var(--color-space-blue-1);
        }
      }
    }
  }

  &--color-blue {
    color: var(--color-cyan);
    border-radius: 4px;

    .main-button__background {
      border: 2px solid var(--color-cyan);
      border-radius: 4px;
    }

    &:hover {
      .main-button__background {
        background-color: var(--color-cyan);
      }

      .main-button__inner {
        &__icon {
          svg {
            fill: var(--color-space-blue-1) !important;
          }
        }

        &__text {
          color: var(--color-space-blue-1);
        }
      }
    }
  }

  &--size-small {
    .main-button__inner {
      padding: 4px 20px;
    }
  }

  &--size-medium {
    .main-button__inner {
      padding: 8px 20px;
    }
  }

  &--size-large {
    .main-button__inner {
      padding: 16px 32px;

      &__icon {
        width: 24px;
        height: 24px;
      }

      &__text + &__icon {
        margin-left: 12px;
      }

      &__icon + &__text {
        margin-left: 12px;
      }
    }
  }

  &--disabled-true {
    filter: grayscale(100%);
    opacity: 0.8;
    pointer-events: none;
  }
}
