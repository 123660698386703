.event-card {
  background: var(--color-white);
  border: 1px solid var(--Neutral-200);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-fast);

  &:not(&--disable-hover):hover {
    border-color: var(--color-main);
    box-shadow: 0px;
    cursor: pointer;

    .title {
      color: var(--color-main);
    }
  }

  .title {
    word-wrap: break-word;
    width: 102.5%;
    position: relative;
    transition: var(--transition-fast);

    &:before {
      content: '';
      background-color: var(--color-main);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 5px;
      height: 30px;
      position: absolute;
      top: -2px;
      left: -25px;
    }
  }

  .text {
    margin-top: 32px;
    color: var(--Neutral-Secondary);
  }

  .group-1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    &__left {
      flex: 1 0 auto;
    }

    &__right {
    }
  }

  .group-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 8px;
  }

  .date-time {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    color: var(--Neutral-Secondary);

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
    }
  }

  .bottom-part {
    margin-top: 32px;
  }

  &--size-small {
    .title > * {
      font-size: 20px;
    }

    .text {
      margin-top: 8px;
    }
  }

  &--disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
  }
}
