// DevTools.module.scss
.devTools {
  background-color: rgba(30, 30, 30, 0.9);
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  font-size: 14px;
  z-index: 9999;
  min-width: 200px;
  max-width: 300px;
  transition: opacity 0.2s ease;
  user-select: none;

  &.dragging {
    opacity: 0.8;
    transition: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: rgba(50, 50, 50, 0.9);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: move;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.title {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
}

.content {
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(30, 30, 30, 0.9);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  // Scrollbar styling
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(50, 50, 50, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 0.5);
    border-radius: 3px;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radioLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;

  &:hover {
    color: #61dafb;
  }
}

.radioInput {
  margin-right: 8px;
}
