.article {
  cursor: pointer;
  position: relative;

  &__inner {
    border-radius: 16px;
    border: 1px solid var(--Border-Neutral-Default);
    background: var(--Background-Neutral-Container);
    box-shadow: var(--shadow-md);
  }

  .top-part {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid var(--Border-Neutral-Default);
    width: 100%;
    position: relative;
    overflow: hidden;

    .image {
      width: 100%;
      height: 140px;
      position: relative;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }

  .main-part {
    padding: 24px;
  }

  .time {
    display: flex;
    margin-top: 24px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &__text {
      position: relative;
      top: 4px;
    }
  }

  .location {
    display: flex;
    margin-top: 24px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &__text-2 {
      margin-top: 8px;
    }
  }

  .avatar-list {
    display: flex;
    margin-top: 16px;

    &__item {
      border: 2px solid var(--color-white);
      border-radius: 50%;
      width: calc(32px + 4px);
      height: calc(32px + 4px);
      overflow: hidden;

      &:not(:nth-child(1)) {
        margin-left: -8px;
      }

      > img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
