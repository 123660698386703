.block {
  .group-1 {
  }

  .body-part {
    margin-top: 24px;
  }

  .group-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 24px;
  }

  .star-rating {
    display: flex;
    gap: 8px;

    &__list {
      display: flex;
      gap: 8px;
    }
  }

  .tag-list {
    margin-top: 24px;
  }

  .slider {
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .block {
  }
}
