.form {
  color: var(--color-white);
  position: relative;

  .row-submit {
    > * {
      width: 100%;
    }
  }
}

.requirements-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;

  &__item {
    display: flex;
    width: 50%;
    padding-right: 16px;
    margin-top: 16px;

    &__circle {
      background-color: var(--color-white);
      border: 1px solid var(--color-gray-500);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      position: relative;
      top: -1px;
      transition: var(--transition-fast);

      &__tick {
        opacity: 0;
        transition: var(--transition-fast);
      }
    }

    &--invalid {
      .requirements-list__item__circle {
        background-color: var(--color-red-400);
        border-color: var(--color-red-400);
      }
    }

    &--valid {
      .requirements-list__item__circle {
        background-color: var(--color-green-400);
        border-color: var(--color-green-400);

        &__tick {
          opacity: 1;
        }
      }
    }
  }
}
