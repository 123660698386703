.notification-card {
  background: var(--color-white);
  border: 1px solid var(--Neutral-200);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-fast);

  .group-1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    &__left {
      flex: 1 1 auto; // Can grow AND shrink
      min-width: 0; // Allow shrinking below content size if needed
      overflow-wrap: break-word; // Ensure text wraps correctly
      max-width: 80%;
    }

    &__right {
      flex: 1 0 auto; // Don't grow or shrink
      align-self: flex-start; // Align to top when wrapped
    }
  }

  .content {
    display: flex;
    gap: 16px;
    color: var(--Neutral-Secondary);

    &__icon {
      align-items: center;
      justify-content: center;
      min-width: 22px;
      width: 22px;
      height: 22px;
      position: relative;
      top: -2px;
    }

    b,
    strong {
      color: var(--color-black);
    }
  }

  &--color-grey {
    background: var(--color-gray-50);
    border-color: var(--color-gray-300);
  }
}

@media screen and (max-width: 767px) {
  .notification-card {
    .group-1 {
      &__left {
        max-width: 100%;
      }
    }
  }
}
