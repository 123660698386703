.container {
  .widget {
    width: 1000px;
    max-width: 100%;
    min-height: 700px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 1279px) {
  .container {
    .widget {
      min-height: 1300px;
    }
  }
}
