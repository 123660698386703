.slider {
  position: relative;

  :global(.slick-slide:nth-last-child(1)) {
    .slider__item:after {
      display: none;
    }
  }

  :global(.slick-prev),
  :global(.slick-next) {
    width: 16px;
    height: 16px;
    top: initial;
    bottom: calc(-48px - 16px);
    transition: var(--transition-fast);
  }

  :global(.slick-prev) {
    left: calc(50% - 40px);
  }

  :global(.slick-next) {
    right: calc(50% - 40px);
  }

  :global(.slick-prev:before),
  :global(.slick-next:before) {
    content: '';
    background-image: url('data:image/svg+xml,<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.20711 0.292893C8.81658 -0.0976311 8.18342 -0.0976311 7.79289 0.292893C7.40237 0.683418 7.40237 1.31658 7.79289 1.70711L13.0858 7H1.5C0.947715 7 0.5 7.44772 0.5 8C0.5 8.55229 0.947715 9 1.5 9H13.0858L7.79289 14.2929C7.40237 14.6834 7.40237 15.3166 7.79289 15.7071C8.18342 16.0976 8.81658 16.0976 9.20711 15.7071L16.2071 8.70711C16.5976 8.31658 16.5976 7.68342 16.2071 7.29289L9.20711 0.292893Z" fill="%23EA6B3D"/></svg>');
    display: block;
    width: 16px;
    height: 16px;
    transition: var(--transition-fast);
  }

  :global(.slick-prev:before) {
    transform: rotateZ(180deg);
  }

  :global(.slick-prev.slick-disabled:before),
  :global(.slick-next.slick-disabled:before) {
    opacity: 0.66;
    filter: grayscale(100%);
  }

  &--visual-type-default {
    width: calc(100% + 98px);
    padding-bottom: calc(48px + 16px);
    position: relative;
    left: -48px;

    .slider__item {
      padding-right: 49px;
      padding-left: 49px;
      position: relative;

      &:after {
        content: '';
        background-color: var(--color-gray-300);
        width: 1px;
        height: 174px;
        position: absolute;
        margin: auto 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }
    }
  }

  &--visual-type-images {
    overflow: hidden;
  }
}

@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 767px) {
  .slider {
    width: 100%;

    :global(.slick-list) {
      overflow: visible;
    }
  }
}
