.block {
  .group-1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .body-part {
    margin-top: 10px;
  }

  .table {
    overflow-x: auto;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0px;

      &:not(:nth-last-child(1)) {
        border-bottom: 1px solid var(--Neutral-200);
      }
    }

    &__col {
      min-width: 240px;
      width: 30%;
      padding-right: 32px;

      &:nth-child(1) {
        width: 40%;
        min-width: 320px;
      }
    }

    &__content-1 {
      display: flex;
      align-items: center;
      gap: 24px;

      &__image {
        border-radius: 12px;
        width: 80px;
        min-width: 80px;
        height: 80px;
        overflow: hidden;
      }

      &__info {
        &__name {
          display: flex;
          gap: 8px;

          &__icon {
            position: relative;
            top: 7px;
          }
        }

        &__add-data {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          row-gap: 12px;
          margin-top: 8px;

          &__element {
            position: relative;

            &:not(:nth-last-child(1)):after {
              content: '';
              background-color: var(--Neutral-400);
              border-radius: 50%;
              width: 4px;
              height: 4px;
              margin: auto 0px;
              position: absolute;
              top: -3px;
              right: -14px;
              bottom: 0px;
            }
          }
        }
      }
    }

    &__content-2 {
      &__value {
        margin-top: 8px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .block {
    .table {
      &__row {
        align-items: initial;
      }

      &__col {
        width: 240px;

        &:nth-child(1) {
          min-width: 80vw;
        }
      }
    }
  }
}
