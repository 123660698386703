.block {
  .group-1 {
  }

  .body-part {
    margin-top: 24px;
  }

  .map {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin-top: 24px;
    position: relative;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .button-list {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .button-list {
    flex-wrap: wrap;
    row-gap: 12px;

    > * {
      width: 100%;
    }
  }
}
