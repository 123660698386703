.container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 64px;
  padding-right: 142px;
  padding-bottom: 64px;

  .container-top-part {
    height: 19px;
    padding-left: 64px;
  }

  .container-bottom-part {
    height: 19px;
  }

  .content {
    width: 416px;
    max-width: 100%;
    margin-left: auto;
    position: relative;

    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      color: var(--color-white);
      margin-top: 40px;
    }

    .text {
      color: var(--color-white);
      margin-top: 24px;
    }

    .button {
      width: 100%;
      margin-top: 40px;
    }

    .forgot-password {
      margin-top: 16px;
    }

    .sign-in {
      display: flex;
      align-items: center;
      margin-top: 40px;

      &__link {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .container {
    height: initial;
    min-height: initial;
    align-items: initial;
    justify-content: center;
    padding-top: initial;
    padding-right: initial;
    padding-bottom: initial;

    .container-top-part {
      width: 416px;
      max-width: 100%;
      padding-left: initial;
      margin-right: auto;
      margin-bottom: 64px;
      margin-left: auto;
    }

    .content {
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    .content {
      .astronomic-logo {
        width: 124px;
      }

      .form {
        .form-button-group {
          display: block;

          &__item {
            flex: initial;
          }

          &__item:nth-child(1) {
            max-width: 100%;
            margin-right: initial;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
