.list {
  margin-top: 64px;

  &__item {
    display: flex;
    gap: 32px;

    &__icon {
      background-color: var(--color-white);
      border: 2px solid var(--Border-Neutral-Default);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      width: 80px;
      height: 80px;
    }

    &:not(:nth-child(1)) {
      margin-top: 64px;
    }
  }

  ul {
    margin-top: 16px;

    li {
      position: relative;
      padding-left: 16px;

      &:before {
        content: '';
        background-color: var(--color-black);
        border-radius: 50%;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 8px;
        left: 4px;
      }
    }
  }
}

.text-2 {
  b,
  strong {
    color: var(--color-black);
  }
}

.text-3 {
  b,
  strong {
    color: var(--color-black);
  }
}

.text-4 {
  margin-top: 64px;
}

.support {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 64px;

  &__left {
    width: 128px;
    min-width: 128px;
    margin-right: 32px;
  }

  &__right {
    width: calc(100% - 128px - 32px);
  }

  &__avatar {
    border-radius: 8px;
    width: 128px;
    height: 128px;
    overflow: hidden;
  }
}

.button {
  margin-top: 64px;
}

@media screen and (max-width: 767px) {
  .list {
    &__item {
      flex-wrap: wrap;

      &__info {
        width: 100%;
      }
    }
  }

  .support {
    &__right {
      width: 100%;
      margin-top: 32px;
    }
  }

  .button {
    width: 100%;
    margin-top: 64px;
  }
}
