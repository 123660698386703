.account-header {
  padding-bottom: 6.65vw;

  & + :global(.section-divider) {
    margin-top: -6.65vw;
  }

  :global(.wrapper) {
    padding-top: 104px;
  }

  &__bg {
    background-image: url('../../../../../asset/images/account_header_bg.jpg');

    background-size: cover;
    background-position: top right;
  }

  :global(.content) {
    color: var(--color-white);
    position: relative;
  }

  .block-list {
    display: flex;
    justify-content: space-between;

    &__item-1 {
      display: flex;
      max-width: calc(100% - 412px - 24px);
      min-height: 106px;
      margin-right: 24px;
    }

    &__item-2 {
      min-height: 80px;
      position: relative;
    }
  }

  .avatar {
    min-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 24px;
    margin-right: 32px;
    position: relative;
    overflow: hidden;
  }

  .account-info {
    &__name-container {
      &__text-2 {
        margin-bottom: 8px;
      }
    }

    &__location-container {
      display: flex;
      align-items: center;
      margin-top: 16px;

      &__icon {
        margin-right: 8px;
      }
    }
  }

  .ill {
    position: absolute;
    right: 0px;
    bottom: -120px;
    z-index: 55;
    pointer-events: none;

    > * {
      max-width: 414px;
    }
  }
}

@media screen and (max-width: 767px) {
  .account-header {
    .block-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: initial;

      &__item-1 {
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        margin-right: initial;
      }

      &__item-2 {
        width: 100%;
        margin-top: 48px;
        margin-bottom: -96px;
      }
    }

    .avatar {
      margin-right: initial;
    }

    .account-info {
      width: 100%;
      margin-top: 24px;

      &__name-container {
        justify-content: space-between;
      }
    }

    .ill {
      display: flex;
      justify-content: center;
      position: relative;
      top: initial;
      right: initial;
      bottom: initial;

      > * {
        max-width: 100%;
      }
    }
  }
}
