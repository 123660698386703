.notification-card {
  margin-top: 32px;
}

.tablemates {
  margin-top: 32px;
}

.section-type-1 {
  &__layout {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 32px;

    & > *:nth-child(1) {
      width: calc(100% - 420px - 32px);
    }

    & > *:nth-child(2) {
      width: 420px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .section-type-1 {
    &__layout {
      & > *:nth-child(1) {
        width: calc(50% - 16px);
      }

      & > *:nth-child(2) {
        width: calc(50% - 16px);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section-type-1 {
    &__layout {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      margin-top: 32px;

      & > *:nth-child(1) {
        width: 100%;
      }

      & > *:nth-child(2) {
        width: 100%;
      }
    }
  }
}
