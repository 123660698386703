.section {
  color: var(--color-white);
  overflow: hidden;

  :global(.background) {
    background-color: var(--color-space-blue-1);
  }

  .bg-elements > * {
    display: block;
    width: 100%;
  }

  .block-list {
    display: flex;
    position: relative;

    &__item-1 {
      width: 50%;
    }

    &__item-2 {
      order: -1;
      width: 50%;
    }
  }

  .sub-title {
    color: var(--color-main);
    margin-bottom: 8px;
  }

  .text {
    margin-top: 24px;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-top: 64px;
    position: relative;
    left: -8px;

    &__item {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  .image {
    width: 664px;
    position: relative;
    left: -64px;
    pointer-events: none;
  }
}

@media screen and (max-width: 1279px) {
  .section {
    :global(.content) {
      padding-bottom: initial;
    }

    .block-list {
      flex-wrap: wrap;

      &__item-1 {
        order: 1;
        width: 100%;
      }

      &__item-2 {
        order: 2;
        width: 100%;
        margin-top: 40px;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      width: auto;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      left: initial;
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    .button-group {
      &__item {
        width: 100%;
        margin-right: initial;
        margin-left: initial;

        &:not(:nth-child(1)) {
          margin-top: 16px;
        }

        > * {
          width: 100%;
        }
      }
    }
  }
}
